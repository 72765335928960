/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Select from "react-select"
import { optionsProduct } from "@components/pageHablemos/components/util"
import { customStylesPais, customStyles } from "@components/pageHablemos/components/stylesSelect"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"

const ViewOne = ({
  countries,
  setSectionView,
  setDataOne,
  onClickConfirm,
  dataOne,
}) => {
  //Estado valores inputs
  const [values, setValues] = useState({
    name: dataOne.name ? dataOne.name : "",
    lastname: dataOne.lastname ? dataOne.lastname : "",
    email: dataOne.email ? dataOne.email : "",
    company: dataOne.company ? dataOne.company : "",
    jobtitle: dataOne.jobtitle ? dataOne.jobtitle : "",
    website: dataOne.website ? dataOne.website : "",
    phone: dataOne.phone ? dataOne.phone : "",
    pais: dataOne.pais ? dataOne.pais : "default",
    products: dataOne.products ? dataOne.products : "",
    codeCountry: dataOne.codeCountry
      ? dataOne.codeCountry
      : {
          value: "Perú",
          label: "Perú",
          image: "https://flagcdn.com/w320/pe.png",
          code: "+51",
        },
  })

  //Estado validaciones
  const [validations, setValidations] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    jobtitle: "",
    website: "",
    pais: "",
    phone: "",
    codeCountry: "",
    products: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const {
      name,
      lastname,
      email,
      company,
      jobtitle,
      website,
      pais,
      phone,
      codeCountry,
      products,
    } = values
    const Blacklist = [
      "@gmail.com",
      "@hotmail.com",
      "@yahoo.com",
      "@outlook.com",
      "yahoo.es",
      "yahoo.com.mx",
      "icloud.com",
      "gmail.co",
      "hotmail.es",
      "hotmail.it",
      "outlook.com",
      "outlook.es",
    ]

    const validations = {
      name: "",
      lastname: "",
      email: "",
      company: "",
      jobtitle: "",
      website: "",
      pais: "",
      phone: "",
      codeCountry: "",
      products: "",
    }
    let isValid = true

    if (!name) {
      validations.name = "Campo requerido"
      isValid = false
    }
    if (name && !/^[a-zA-Z\s]+$/.test(name)) {
      validations.name = "Ingresar solo letras"
      isValid = false
    }
    if (!lastname) {
      validations.lastname = "Campo requerido"
      isValid = false
    }

    if (lastname && !/^[a-zA-Z\s]+$/.test(lastname)) {
      validations.lastname = "Ingresar solo letras"
      isValid = false
    }

  
    if (!email) {
      validations.email = "Campo requerido"
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "El formato debe ser el example@mail.com"
      isValid = false
    }
    if (Blacklist) {
      for (let i = 0; i < Blacklist.length; i++)
        if (email.toLowerCase().includes(Blacklist[i]) === true) {
          validations.email = "Ingrese un correo corporativo"
          isValid = false
        }
    }
    if (!phone) {
      validations.phone = "Campo requerido"
      isValid = false
    }

    if (phone && !phone.match(/^[0-9]+$/)) {
      validations.phone = "Ingresar solo numeros"
      isValid = false
    }
    if (!company) {
      validations.company = "Campo requerido"
      isValid = false
    }
    if (company && !/^[a-zA-Z\s]+$/.test(company)) {
      validations.company = "Ingresar solo letras"
      isValid = false
    }
    if (!jobtitle) {
      validations.jobtitle = "Campo requerido"
      isValid = false
    }
    if (jobtitle && !/^[a-zA-Z\s]+$/.test(jobtitle)) {
      validations.jobtitle = "Ingresar solo letras"
      isValid = false
    }
    if (!website) {
      validations.website = "Campo requerido"
      isValid = false
    }
    if (pais === "default") {
      validations.pais = "Campo requerido"
      isValid = false
    }
    if (products === "") {
      validations.products = "Campo requerido"
      isValid = false
    }
    if (codeCountry === "default") {
      validations.codeCountry = "Campo requerido"
      isValid = false
    }
    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }


    if (
      (name === "name" ||
        name === "lastname" ||
        name === "company" ||
        name === "jobtitle") &&
      !/^[a-zA-Z\s]+$/.test(value)
    ) {
      message = "Ingresar solo letras"
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "El formato debe ser el example@mail.com"
    }
    if (value && name === "phone" && !/^[0-9]+$/.test(value)) {
      message = "Ingresar solo numeros"
    }

    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const {
    name,
    lastname,
    email,
    company,
    jobtitle,
    website,
    products,
    phone,
    pais,
  } = values

  const {
    name: nameVal,
    lastname: lastnameVal,
    email: emailVal,
    company: companyVal,
    jobtitle: jobtitleVal,
    website: websiteVal,
    pais: paisVal,
    phone: phoneVal,
    codeCountry: codeCountryVal,
    products: productsVal,
  } = validations

  const handleChangeCountry = value => {
    setValues({ ...values, codeCountry: value })
  }

  const handleChangePais = value => {
    setValues({ ...values, pais: value })
  }

  const [selectedOptionProduct, setSelectedOptionProduct] = useState(
    dataOne.products ? dataOne.products : ""
  )

  const handleOptionProductChange = option => {
    setSelectedOptionProduct(option.id)
    setValues({ ...values, products: option.id })
  }

  useEffect(() => {
    setDataOne(values)
  }, [values])

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  //Funcion envio formulario
  const handleSubmit = async () => {
    const isValid = validateAll()
    //Validar los campos
    if (isValid) {
      if (
        values.products === "platcon" ||
        values.products === "voice_api" ||
        values.products === "sip_trunk"
      ) {
        setSpin(true)
        setTimeout(() => {
          setSpin(false)
          onClickConfirm(true)
        }, 5000)
        try {
          const params = new URLSearchParams()
          params.append("landing", "Hablemos") // Esto debe ser "Hablemos"
          params.append("firstname", dataOne.name)
          params.append("lastname", dataOne.lastname)
          params.append("email", dataOne.email)
          params.append("company", dataOne.company)
          params.append("jobtitle", dataOne.jobtitle)
          params.append("website", dataOne.website)
          params.append("codeCountry", dataOne.codeCountry.code)
          params.append("phone", dataOne.phone)
          params.append("country", dataOne.pais.label)
          params.append("product", dataOne.products)

          await fetch(`/backend/contactus.php`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "*/*",
            },
            body: params,
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        setSectionView(false)
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
      }
    } else {
      return false
    }
  }

  return (
    <div className={"container-page-hablemos-form"}>
      <hr className="container-page-hablemos-form-grey" />
      <hr className="container-page-hablemos-form-blue" />
      <div className="container-page-hablemos-form-form">
        <div className="container-page-hablemos-form-title">
          <p className="container-page-hablemos-form-title_title">
            Datos personales y de tu empresa
          </p>
          <p>
            Te pediremos algunos datos importantes para que un experto en
            soluciones te ayude de la manera más acertada. Tómate tu tiempo, no
            tardarás más de dos minutos. 😊
          </p>
        </div>
        <div className="container-page-hablemos-form-inputContainer">
          <div>
            <p className="container-page-hablemos-form-inputContainer_label">
              Nombres
            </p>
            <input
              className="container-page-hablemos-form-inputContainer_input"
              placeholder="Ejemplo: María"
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="error">{nameVal}</div>
          </div>

          <div>
            <p className="container-page-hablemos-form-inputContainer_label">
              Apellidos
            </p>
            <input
              className="container-page-hablemos-form-inputContainer_input"
              placeholder="Ejemplo: Timaná Gonzáles"
              type="text"
              name="lastname"
              value={lastname}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="error">{lastnameVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-inputContainer">
          <div>
            <p className="container-page-hablemos-form-inputContainer_label">
              Correo corporativo
            </p>
            <input
              className="container-page-hablemos-form-inputContainer_input"
              placeholder="Ejemplo: maria.mora@beex.pe"
              type="text"
              name="email"
              value={email}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="error">{emailVal}</div>
          </div>
          <div className="">
            <p className="container-page-hablemos-form-inputContainer_label">
              Teléfono de contacto
            </p>
            <div className="container-page-hablemos-form-inputContainer_code-phone">
              <div>
                <Select
                  options={countries}
                  defaultValue={{
                    value: "Perú",
                    label: "Perú",
                    image: "https://flagcdn.com/w320/pe.png",
                    code: "+51",
                  }}
                  onChange={value => handleChangeCountry(value)}
                  placeholder={"Codigo"}
                  styles={customStyles}
                  formatOptionLabel={country => (
                    <div className="country-option">
                      <img src={country.image} alt="country-image" />
                      <span>{country.code}</span>
                    </div>
                  )}
                />
                <div className="error">{codeCountryVal}</div>
              </div>

              <div>
                <input
                  className="input-hero"
                  placeholder="Telefono"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{phoneVal}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-page-hablemos-form-inputContainer">
          <div>
            <p className="container-page-hablemos-form-inputContainer_label">
              Empresa
            </p>
            <input
              className="container-page-hablemos-form-inputContainer_input"
              placeholder="Ejemplo: Beex"
              type="text"
              name="company"
              value={company}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="error">{companyVal}</div>
          </div>
          <div>
            <p className="container-page-hablemos-form-inputContainer_label">
              Puesto en la empresa
            </p>
            <input
              className="container-page-hablemos-form-inputContainer_input"
              placeholder="Ejemplo: Ejecutivo de ventas"
              type="text"
              name="jobtitle"
              value={jobtitle}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="error">{jobtitleVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-inputContainer">
          <div>
            <p className="container-page-hablemos-form-inputContainer_label">
              Página web de la empresa
            </p>
            <input
              className="container-page-hablemos-form-inputContainer_input"
              placeholder="Ejemplo: www.beex.pe"
              type="text"
              name="website"
              value={website}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="error">{websiteVal}</div>
          </div>
          <div className="select-paises">
            <p className="container-page-hablemos-form-inputContainer_label">
              País de la empresa
            </p>
            <Select
              options={countries}
              value={pais}
              onChange={value => handleChangePais(value)}
              placeholder={"Seleccionar"}
              styles={customStylesPais}
              formatOptionLabel={country => (
                <div className="country-option">
                  <img src={country.image} alt="country-image" />
                  <span>{country.label}</span>
                </div>
              )}
            />

            <div className="error">{paisVal}</div>
          </div>
        </div>

        <div className="container-page-hablemos-form-container_radio">
          <p className="container-page-hablemos-form-inputContainer_label">
            ¿En cuál producto estás interesado?
          </p>
          <div className="container-page-hablemos-form-container_radio-products">
            {optionsProduct.map(option => (
              <div
                key={option.id}
                className={
                  option.type === "product"
                    ? `container-page-hablemos-form-container_radio-products-card-product container-page-hablemos-form-container_radio-products-card ${
                        selectedOptionProduct === option.id ? "selected" : ""
                      }`
                    : `container-page-hablemos-form-container_radio-products-card ${
                        selectedOptionProduct === option.id ? "selected" : ""
                      }`
                }
              >
                <input
                  type="radio"
                  id={`product${option.id}`}
                  name="products"
                  value={products}
                  checked={selectedOptionProduct === option.id}
                  onChange={() => handleOptionProductChange(option)}
                />

                <label htmlFor={`product${option.id}`}>
                  <div className="container-page-hablemos-form-container_radio-products-card-info">
                    <img  src={option.image} alt={option.title} />
                    <div>
                      <p>
                        <strong>{option.title}</strong>{" "}
                      </p>
                      <p>{option.description}</p>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <br />
          <div className="error">{productsVal}</div>
        </div>

        <div
          style={{
            margin: "31px auto 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="container-page-hablemos-form-btnPrimary"
            onClick={e => handleSubmit(e)}
          >
            Continuar{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L13.5303 18.5303C13.2374 18.8232 12.7626 18.8232 12.4697 18.5303C12.1768 18.2374 12.1768 17.7626 12.4697 17.4697L17.1893 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H17.1893L12.4697 6.53033C12.1768 6.23744 12.1768 5.76256 12.4697 5.46967Z"
                fill="#262A31"
              />
            </svg>
            {spin ? (
              <Loader type="Oval" color="#5e5e5e" height={20} width={20} />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewOne
